import React from "react"
import Title from "./Title"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allStrapiStudies(sort: {fields: strapiId, order: DESC}) {
      nodes {
        strapiId
        degree
        university
        date
        courses {
          id
          title
        }
        field
      }
    }
  }
`

const Studies = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiStudies: { nodes : studies },
  } = data
  const [value, setValue]= React.useState(0)
  const { university, date, courses, field } = studies[value]

  return (
  <section className="section studies"> 
    <Title title="Education" />
    <div className="studies-center">
      {/* btn container */}
      <div className="btn-container">
        {studies.map((item, index)=>{
          return <button key={item.strapiId} onClick={()=>setValue(index)} className={`study-btn ${index === value && 'active-btn'}`}>
              {item.degree}
            </button>
        })}
      </div>
      {/* Studies info */}
      <article className="study-info">
        <h3>{university}</h3>
        <h4>{field}</h4>
        
        <p className="study-date">{date}</p>
        <h5>Relevant Courses</h5>
        <div className="about-stack">
            {courses.map((item)=>{
            return <span key={item.id}>{item.title}</span>
            })}
        </div>
      </article>
    </div>
    
  </section>
  )
}

export default Studies
