import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
//import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"
import Studies from "../components/Studies"
export default ({data}) => {
  const {
    allStrapiProjects:{nodes:projects},
    allStrapiBlogs:{nodes:blogs}
  } = data

  return (
  <Layout>
    <SEO title="Home" description="This is AkhilMovva's Portfolio"/>
    <Hero />
    <Services />
    <Studies />
    {/* <Jobs /> */}
    <Projects projects={projects} title="featured projects" showLink />
    <Blogs blogs={blogs} title="latest blogs" showLink />
  </Layout>
  )
}
// ...GatsbyImageSharpFluid
export const query = graphql`
  {
    allStrapiProjects(filter: {featured: {eq: true}}) {
      nodes {
        github
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }

    allStrapiBlogs(sort: {fields: date, order: DESC}, limit: 3) {
      nodes {
        slug
        content
        desc
        date(formatString: "MMMM Do, YYYY")
        id
        title
        category
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`