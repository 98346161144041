import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
// ...GatsbyImageSharpFluid
const query = (graphql`
    {
      file(relativePath: {eq: "akhil-blob.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
const Hero = () => {
  const {
    file: {
      childImageSharp:{ fluid },
    },
  } = useStaticQuery(query)
  
  return <header className="hero">
    <div className="section-center hero-center">
      <article className="hero-info">
        <div>
          <div className="underline"></div>
          <h1>i'm Akhil M</h1>
          <h4>Looking for full-time in Cloud Domain</h4>
          <Link to='/contact' className="btn">
            contact me
          </Link>
          <SocialLinks />
        </div>
      </article>
      <Image fluid ={fluid} className="hero-img" />
    </div>
  </header>
}

export default Hero
