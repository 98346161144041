import React from "react"
import { FaDocker, FaHubspot, FaAws } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaDocker className="service-icon" />,
    title: "DevOps",
    text: `DevOps is an ITSM framework that defines the mindset, culture, and philosophy of working on an IT project as a collaboration among developers, operations, and QA teams.`,
  },
  {
    id: 2,
    icon: <FaHubspot className="service-icon" />,
    title: "SRE",
    text: `Site reliability engineering (SRE) fuses the software engineering and operations disciplines. The SRE role enables collaboration and information sharing between Dev and Ops departments for additional specific objectives.`,
  },
  {
    id: 3,
    icon: <FaAws className="service-icon" />,
    title: "Cloud Engineer",
    text: `Cloud Engineers use their expertise to decide on which services to adopt and which to avoid (and why), and establish the best practices for software engineering too, quite like DevOps and SREs.`,
  },
]
